<template>
  <v-container
    class="pt-0"
    fluid
  >
    <div
      v-if="showTooltip"
      ref="svgTooltip"
      class="d-flex justify-center align-center pa-2"
      :style="`position: fixed; top: ${tooltipY}px; left: ${tooltipX}px;
           min-width: 100px; min-height: 50px; background: white; border-radius: 15px;
           opacity: .9; z-index: 1000;
         `"
    >
      <span>
        {{ tooltipText }}
      </span>
    </div>
    <v-row>
      <v-col cols="12">
        <v-card
          style="width: 100%; height: 100%"
          class="pa-2"
        >
          <v-row class="align-center">
            <v-card-title
              class="text-h3 font-weight-bold grey--text mt-2 pb-0 ml-4"
            >
              Audit View
            </v-card-title>
            <v-btn
              text
              :disabled="loadingPossiblyAdded || loadingPossiblyRemoved"
              class="font-weight-bold mt-6"
              color="green"
              @click="compareTables"
            >
              Compare Tables
            </v-btn>
            <v-btn
              text
              :disabled="loadingPossiblyAdded || loadingPossiblyRemoved"
              color="blue"
              class="font-weight-bold mt-6"
              @click="showCsvDownloadDialog = true"
            >
              Export To CSV
            </v-btn>
            <v-btn
              text
              :loading="syncingChanges"
              :disabled="possiblyAddedData.length === 0"
              color="blue"
              class="font-weight-bold mt-6"
              @click="syncChanges"
            >
              Sync Changes
            </v-btn>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="6">
              <v-row class="justify-center align-center ma-1 font-weight-bold">
                <div>Possibly Removed Equipment</div>
                <v-btn
                  text
                  class="font-weight-bold"
                  color="green"
                  @click="showAddPossiblyRemovedDialog = true"
                >
                  Add
                </v-btn>
                <v-btn
                  text
                  :disabled="!selectedRemovedTableItem"
                  :loading="deletingPossiblyRemoved"
                  class="font-weight-bold"
                  color="red"
                  @click="deleteAuditPotentialRemoved"
                >
                  Remove
                </v-btn>
                <v-btn
                  text
                  :loading="searchingEquipmentRemoved"
                  :disabled="!selectedRemovedTableItem"
                  class="font-weight-bold"
                  color="blue"
                  @click="getEquipmentBySerialOrAssetTag(selectedRemovedTableItem, 'Removed')"
                >
                  Search
                </v-btn>
              </v-row>
              <v-data-table
                :headers="headersForPossiblyRemoved"
                single-select
                item-key="id"
                :loading="loadingPossiblyRemoved"
                loading-text="Retrieving Equipment"
                :items="possiblyRemovedData"
                :fixed-header="true"
                calculate-widths
                height="600"
                :value="selectedRemovedTableRow"
                @click:row="
                  (item, row) => {
                    row.select(true)
                    selectedRemovedTableItem = item
                  }
                "
              >
                <template
                  v-for="header in headersForPossiblyRemoved.filter((header) =>
                    header.hasOwnProperty('formatter')
                  )"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <template v-slot:[`body.prepend`]>
                  <tr>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldLabel"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                        label="filter..."
                      />
                    </td>
                    <td>
                      <v-select
                        v-model="removedTableFieldStatus"
                        :items="['Arrived', 'Deployed', 'Decommissioned', 'Disposed']"
                        class="pt-4"
                        outlined
                        dense
                      >
                        <v-btn
                          v-if="removedTableFieldStatus"
                          slot="prepend-inner"
                          x-small
                          style="margin-top: 3px;"
                          text
                          @click="removedTableFieldStatus = ''"
                        >
                          <v-icon size="small">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-select>
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldSerial"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldAssetTag"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldModel"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldRackLocation"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldUnitLocation"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldBay"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldNotes"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldNotes"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldAssetId"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldProjectNumber"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldProjectName"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldDepartment"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldAcquisitionDate"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldDecommissionDate"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldDisposalDate"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="removedTableFieldDescription"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="6"
            >
              <v-row
                class="justify-center align-center ma-1 font-weight-bold"
              >
                <div>Possibly Added Equipment</div>
                <v-btn
                  text
                  class="font-weight-bold"
                  color="green"
                  @click="showAddPossiblyNewDialog = true"
                >
                  Add
                </v-btn>
                <v-btn
                  text
                  :disabled="!selectedAddedTableItem"
                  class="font-weight-bold"
                  color="green"
                  @click="showUpdatePossiblyNewDialog = true"
                >
                  Update
                </v-btn>
                <v-btn
                  text
                  :loading="deletingPossiblyAdded"
                  :disabled="!selectedAddedTableItem"
                  class="font-weight-bold"
                  color="red"
                  @click="deleteAuditPotentialAdded"
                >
                  Remove
                </v-btn>
                <v-btn
                  text
                  class="font-weight-bold"
                  color="blue"
                  :loading="searchingEquipmentAdded"
                  :disabled="!selectedAddedTableItem"
                  @click="getEquipmentBySerialOrAssetTag(selectedAddedTableItem, 'Added')"
                >
                  Search
                </v-btn>
              </v-row>
              <v-data-table
                style="border-left: 2px dashed grey; padding-left: 10px;"
                :headers="headersForPossiblyAdded"
                single-select
                item-key="id"
                :loading="loadingPossiblyAdded"
                loading-text="Retrieving Equipment"
                :items="possiblyAddedData"
                :fixed-header="true"
                calculate-widths
                height="600"
                :value="selectedAddedTableRow"
                @click:row="
                  (item, row) => {
                    row.select(true)
                    selectedAddedTableItem = item
                  }
                "
              >
                <template v-slot:[`body.prepend`]>
                  <tr>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldLabel"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                        label="filter..."
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldSerial"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldAssetTag"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldFloorplan"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldSite"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldModel"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldRackLocation"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldUnitLocation"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldBay"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="addedTableFieldNotes"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        v-if="showAlert"
        class="px-3 pb-0"
        cols="12"
      >
        <v-alert
          :type="alertType"
          class="mb-0"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      timeout="3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
    <v-dialog
      v-model="showAddPossiblyRemovedDialog"
      width="600px"
      persistent
    >
      <v-card
        class="pa-6"
        style="overflow-y: scroll;"
        height="300px"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Add Possibly Missing Equipment
        </v-card-title>
        <div
          class="my-4"
        >
          <div class="mb-3">
            Enter serial number and/or asset tag:
          </div>
          <div class="d-flex flex-wrap">
            <v-text-field
              v-model="stateSerialAddRemoved"
              class="mx-3"
              width="45%"
              dense
              outlined
              label="Serial"
              type="text"
            />
            <v-text-field
              v-model="stateAssetTagAddRemoved"
              class="mx-3"
              width="45%"
              dense
              outlined
              label="Asset Tag"
              type="text"
            />
          </div>
          <div
            style="width: 100%"
            class="d-flex justify-center"
          >
            <v-btn
              :disabled="!stateAssetTagAddRemoved && !stateSerialAddRemoved"
              :loading="addingPossiblyMissing"
              text
              class="font-weight-bold"
              color="green"
              @click="insertAuditPotentialRemovedUsingSerialOrAssetTag"
            >
              Add
            </v-btn>
            <v-btn
              :disabled="addingPossiblyMissing"
              class="font-weight-bold"
              text
              color="red"
              @click="
                showAddPossiblyRemovedDialog = false
                stateAssetTagAddRemoved = ''
                stateSerialAddRemoved = ''
              "
            >
              Cancel
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-alert
        v-if="showAddPossiblyRemovedAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ addPossiblyRemovedAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showAddPossiblyNewDialog"
      width="80vw"
    >
      <v-card
        class="pa-6"
        style="overflow-y: scroll;"
        height="90vh"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Add Possibly New Equipment
        </v-card-title>
        <ewc-audit-equipment-flow :local-bus="localBus" />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedAddedTableItem"
      :key="selectedAddedTableItem.id"
      v-model="showUpdatePossiblyNewDialog"
      width="80vw"
    >
      <v-card
        v-show="!loadingEquipmentState"
        class="pa-6"
        style="overflow-y: scroll;"
        height="90vh"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Update Possibly New Equipment
        </v-card-title>
        <ewc-audit-equipment-flow
          :local-bus="localBus"
          :edit-equipment="true"
          :initial-state-object="updateInitialStateObject"
        />
      </v-card>
      <v-card
        v-if="loadingEquipmentState"
        style="width: 950px; height: 100%"
      >
        <v-sheet
          color="grey--lighten-1"
          class=""
        >
          <v-skeleton-loader
            height="500px"
            type="image"
          />
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSearchResultsDialog"
      width="80vw"
    >
      <v-card
        class="pa-6"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Search Results
        </v-card-title>
        <v-data-table
          v-if="searchResults.length > 0"
          :headers="headersForPossiblyRemoved"
          single-select
          item-key="id"
          :items="searchResults"
          :fixed-header="true"
          calculate-widths
        >
          <template
            v-for="header in headersForPossiblyRemoved.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCompareResultsDialog"
      width="80vw"
    >
      <v-card
        class="pa-6"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          <div>Compare Results <span class="text-h5"> (Colored columns show any differences between the two tables for the piece of equipment) </span></div>
        </v-card-title>
        <v-data-table
          v-if="compareResults.length > 0"
          :headers="headersForPossiblyRemoved"
          item-key="id"
          :items="compareResults"
          :fixed-header="true"
          calculate-widths
        >
          <template
            v-for="header in headersForPossiblyRemoved"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            <div
              :key="header.value + (Math.random() + 1).toString(36).substring(7)"
              :class="checkChanges(value)"
              style="width: 100%; height: 100%;"
            >
              {{ checkFormatter(header, value) }}
            </div>
          </template>
        </v-data-table>
        <div v-else>
          There were no matches found between the tables
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCsvDownloadDialog"
      persistent
      max-width="400"
    >
      <v-card class="pa-4 px-6">
        <v-card-title class="text-h5 pl-0">
          Download CSV
        </v-card-title>
        <div
          class="d-flex"
        >
          <v-text-field
            v-model="filename"
            label="Filename"
          />
          <span class="pt-8 ml-1">.csv</span>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="downloadCsv"
          >
            Save
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click="showCsvDownloadDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import common from '@/api/common.js'
  import ewcApi from '@/api/ewc.js'
  import moment from 'moment'
  import { convertAuditTablesToCsv } from '@/util/converters'

  export default {
    name: 'EWCAuditView',
    data: () => ({

      localBus: new Vue(),
      searchingEquipmentRemoved: false,
      searchingEquipmentAdded: false,
      searchResults: [],

      showAlert: false,
      alertType: 'info',
      alertMessage: '',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',
      alertMessageNoResults: 'We were unable to find equipment matching the provided information.',
      alertMessageDuplicate: 'The equipment you are attempting to add is already placed in the audit potentially removed table',

      skipFinallyBlock: false,

      possiblyRemovedData: [],
      loadingPossiblyRemoved: false,
      selectedRemovedTableItem: null,
      removedTableFieldLabel: '',
      removedTableFieldStatus: '',
      removedTableFieldSerial: '',
      removedTableFieldAssetTag: '',
      removedTableFieldModel: '',
      removedTableFieldRackLocation: '',
      removedTableFieldUnitLocation: '',
      removedTableFieldBay: '',
      removedTableFieldNotes: '',
      removedTableFieldAssetId: '',
      removedTableFieldProjectNumber: '',
      removedTableFieldProjectName: '',
      removedTableFieldDepartment: '',
      removedTableFieldAcquisitionDate: '',
      removedTableFieldDecommissionDate: '',
      removedTableFieldDisposalDate: '',
      removedTableFieldDescription: '',
      selectedRemovedTableRow: [],

      loadingPossiblyAdded: false,
      selectedAddedTableItem: null,
      addedTableFieldLabel: '',
      addedTableFieldStatus: '',
      addedTableFieldSerial: '',
      addedTableFieldAssetTag: '',
      addedTableFieldModel: '',
      addedTableFieldRackLocation: '',
      addedTableFieldUnitLocation: '',
      addedTableFieldBay: '',
      addedTableFieldNotes: '',
      addedTableFieldFloorplan: '',
      addedTableFieldSite: '',
      selectedAddedTableRow: [],
      possiblyAddedData: [],

      deletingPossiblyRemoved: false,
      deletingPossiblyAdded: false,

      showSnackBar: false,
      snackBarMessage: '',
      snackBarMessageDeleteSuccess: 'Succesfully removed equipment from table!',
      snackBarMessageSyncSuccess: 'Succesfully added all audit equipment to their respective floorplans!',
      alertMessageNoChange: 'None of the equipment were added, please check serial numbers, asset tags or locations and try again',
      alertMessagePartialSuccess: 'Some equipment were not added, please check serial numbers, asset tags or locations and try again',

      showAddPossiblyRemovedDialog: false,
      stateAssetTagAddRemoved: '',
      stateSerialAddRemoved: '',
      showAddPossiblyRemovedAlert: false,
      addPossiblyRemovedAlertMessage: '',
      addingPossiblyMissing: false,

      showAddPossiblyNewDialog: false,
      showUpdatePossiblyNewDialog: false,
      loadingEquipmentState: true,
      showSearchResultsDialog: false,

      showCompareResultsDialog: false,
      compareResults: [],

      filename: '',
      showCsvDownloadDialog: false,

      syncingChanges: false,

      showTooltip: false,
      tooltipY: 0,
      tooltipX: 0,
      tooltipText: '',
    }),
    computed: {
      headersForPossiblyRemoved () {
        return [
          {
            text: 'Label',
            value: 'equipmentEwc.label',
            width: '150',
          },
          {
            text: 'Status',
            value: 'status',
            width: '200',
          },
          {
            text: 'Serial',
            value: 'serial',
            width: '150',
          },
          {
            text: 'Asset Tag',
            value: 'assetTag',
            width: '150',
          },
          {
            text: 'Model',
            value: 'modelName',
            width: '150',
          },
          {
            text: 'Site',
            value: 'siteName',
            width: '150',
          },
          {
            text: 'Floorplan',
            value: 'gridName',
            width: '150',
          },
          {
            text: 'Rack Location',
            value: 'rackLocation',
            width: '200',
          },
          {
            text: 'Unit Location',
            value: 'unitLocation',
            width: '200',
          },
          {
            text: 'Bay',
            value: 'bay',
            width: '100',
          },
          {
            text: 'Notes',
            value: 'notes',
            width: '300',
          },
          {
            text: 'Asset Id',
            value: 'assetId',
            width: '150',
          },
          {
            text: 'Project Number',
            value: 'projectNumber',
            width: '200',
          },
          {
            text: 'Project Name',
            value: 'projectName',
            width: '200',
          },
          {
            text: 'Department',
            value: 'departmentNumber',
            width: '150',
          },
          {
            text: 'Acquisition Date',
            value: 'acquisitionDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Decommission Date',
            value: 'decommissionDate',
            width: '220',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Disposal Date',
            value: 'disposalDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Description',
            value: 'description',
            width: '150',
          },
        ]
      },
      headersForPossiblyAdded () {
        return [
          {
            text: 'Label',
            value: 'equipmentEwc.label',
            width: '150',
          },
          {
            text: 'Serial',
            value: 'serial',
            width: '150',
          },
          {
            text: 'Asset Tag',
            value: 'assetTag',
            width: '150',
          },
          {
            text: 'Model',
            value: 'modelName',
            width: '150',
          },
          {
            text: 'Site',
            value: 'siteName',
            width: '150',
          },
          {
            text: 'Floorplan',
            value: 'gridName',
            width: '150',
          },
          {
            text: 'Rack Location',
            value: 'rackLocation',
            width: '200',
          },
          {
            text: 'Unit Location',
            value: 'unitLocation',
            width: '200',
          },
          {
            text: 'Bay',
            value: 'bay',
            width: '100',
          },
          {
            text: 'Notes',
            value: 'notes',
            width: '300',
          },
        ]
      },
      updateInitialStateObject () {
        if (!this.selectedAddedTableItem) return null
        return {
          equipmentData: {
            assetTag: this.selectedAddedTableItem.assetTag,
            bay: this.selectedAddedTableItem.bay,
            equipmentModelId: this.selectedAddedTableItem.equipmentModelId,
            notes: this.selectedAddedTableItem.notes,
            rackLocation: this.selectedAddedTableItem.rackLocation,
            serial: this.selectedAddedTableItem.serial,
            unitLocation: this.selectedAddedTableItem.unitLocation,
            id: -this.selectedAddedTableItem.id,
          },
          equipmentModel: {
            capacity: this.selectedAddedTableItem.capacity,
            id: this.selectedAddedTableItem.equipmentModelId,
            imagePath: this.selectedAddedTableItem.imagePath,
            modelColumns: this.selectedAddedTableItem.modelColumns,
            modelRows: this.selectedAddedTableItem.modelRows,
            name: this.selectedAddedTableItem.modelName,
            rackMounted: this.selectedAddedTableItem.rackMounted,
            usize: this.selectedAddedTableItem.usize,
          },
          location: {
            gridId: this.selectedAddedTableItem.gridId,
            gridX: this.selectedAddedTableItem.gridX,
            gridY: this.selectedAddedTableItem.gridY,
            uheight: this.selectedAddedTableItem.uheight,
            uwidth: this.selectedAddedTableItem.uwidth,
            parentEquipmentEwcId: this.selectedAddedTableItem.parentEquipmentEwcId,
            parentAuditEquipmentId: this.selectedAddedTableItem.parentAuditEquipmentId,
            equipmentEwcId: -this.selectedAddedTableItem.id,
            siteId: this.selectedAddedTableItem.siteId,
          },
        }
      },
    },
    created () {
      this.getAllAuditMissingEquipment()
      this.getAllAuditNewEquipment()

      this.localBus.$on('addSuccess', message => {
        this.showSnackBar = true
        this.snackBarMessage = message
        this.showAddPossiblyNewDialog = false
        this.getAllAuditNewEquipment()
      })
      this.localBus.$on('updateAuditPotentialAddedSuccess', message => {
        this.showSnackBar = true
        this.snackBarMessage = message
        this.showUpdatePossiblyNewDialog = false
        this.getAllAuditNewEquipment()
      })
      this.localBus.$on('cancel-potential-add', () => {
        this.showAddPossiblyNewDialog = false
        this.showUpdatePossiblyNewDialog = false
      })

      this.localBus.$on('initialEquipmentStateLoaded', () => {
        this.loadingEquipmentState = false
      })

      this.localBus.$on('display-tooltip', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = label
      })
      this.localBus.$on('hide-tooltip', () => {
        this.showTooltip = false
      })
    },
    methods: {
      getEquipmentBySerialOrAssetTag (selectedItem, searchTable) {
        if (searchTable === 'Removed') {
          this.searchingEquipmentRemoved = true
        } else {
          this.searchingEquipmentAdded = true
        }
        this.searchingEquipment = true
        this.searchResults = []
        this.selectedTableItem = null
        let skipFinallyBlock = false
        this.showAlert = false
        ewcApi.getEquipmentBySerialOrAssetTag(selectedItem.serial || '', selectedItem.assetTag || '')
          .then(result => {
            console.log(result)

            this.searchResults = result.data.map(data => {
              return {
                ...data.equipmentEwc,
                gridId: data.gridId,
                gridName: data.gridName,
                siteId: data.siteId,
                siteName: data.siteName,
              }
            })
            this.showSearchResultsDialog = true
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.getEquipmentBySerialOrAssetTag(selectedItem, searchTable)
            } else {
              this.showAlert = true
              if (error.response?.status === 404) {
                this.alertType = 'info'
                this.alertMessage = this.alertMessageNoResults
              } else {
                this.alertType = 'error'
                this.alertMessage = this.alertMessageGeneral
              }
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.searchingEquipmentAdded = false
              this.searchingEquipmentRemoved = false
            }
          })
      },
      getAllAuditMissingEquipment () {
        this.loadingPossiblyRemoved = true
        ewcApi.getAllAuditMissingEquipment()
          .then(result => {
            console.log(result)
            this.possiblyRemovedData = result.data.map(data => {
              return { gridName: data.gridName, modelName: data.modelName, siteName: data.siteName, ...data.equipmentEwc }
            })
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getAllAuditMissingEquipment()
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.loadingPossiblyRemoved = false })
      },
      getAllAuditNewEquipment () {
        this.loadingPossiblyAdded = true
        ewcApi.getAllAuditNewEquipment()
          .then(result => {
            console.log(result)
            this.possiblyAddedData = result.data
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getAllAuditNewEquipment()
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.loadingPossiblyAdded = false })
      },
      deleteAuditPotentialRemoved () {
        this.deletingPossiblyRemoved = true
        this.showAlert = false
        ewcApi.deleteAuditPotentialRemoved(this.selectedRemovedTableItem.id)
          .then(result => {
            console.log(result)
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageDeleteSuccess
            this.selectedRemovedTableItem = null
            this.getAllAuditMissingEquipment()
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.deleteAuditPotentialRemoved()
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.deletingPossiblyRemoved = false })
      },
      insertAuditPotentialRemovedUsingSerialOrAssetTag () {
        this.addingPossiblyMissing = true
        this.showAddPossiblyRemovedAlert = false
        ewcApi.insertAuditPotentialRemovedUsingSerialOrAssetTag(this.stateSerialAddRemoved, this.stateAssetTagAddRemoved)
          .then(response => {
            console.log(response)
            this.showSnackBar = true
            this.snackBarMessage = 'Succesfully added equipment to audit list'
            this.showAddPossiblyRemovedDialog = false
            this.stateAssetTagAddRemoved = ''
            this.stateSerialAddRemoved = ''
            this.getAllAuditMissingEquipment()
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.insertAuditPotentialRemovedUsingSerialOrAssetTag()
            } else {
              if (error.response.status === 409) {
                this.addPossiblyRemovedAlertMessage = this.alertMessageDuplicate
              } else if (error.response.status === 404) {
                this.addPossiblyRemovedAlertMessage = this.alertMessageNoResults
              } else {
                this.addPossiblyRemovedAlertMessage = this.alertMessageGeneral
              }
              this.showAddPossiblyRemovedAlert = true
            }
          })
          .finally(() => {
            this.addingPossiblyMissing = false
          })
      },

      deleteAuditPotentialAdded () {
        this.deletingPossiblyAdded = true
        this.showAlert = false
        ewcApi.deleteAuditPotentialAdded(this.selectedAddedTableItem.id)
          .then(result => {
            console.log(result)
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageDeleteSuccess
            this.selectedAddedTableItem = null
            this.getAllAuditNewEquipment()
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.deleteAuditPotentialAdded()
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.deletingPossiblyAdded = false })
      },

      compareTables () {
        const equipmentMatches = []
        this.possiblyRemovedData.forEach(removedEquipment => {
          this.possiblyAddedData.forEach(addedEquipment => {
            if ((removedEquipment.serial !== null && removedEquipment.serial === addedEquipment.serial) ||
              (removedEquipment.assetTag !== null && removedEquipment.assetTag === addedEquipment.assetTag)) {
              const matchedEquipment = {}
              for (const [key, value] of Object.entries(removedEquipment)) {
                if (addedEquipment[key] === value || addedEquipment[key] === undefined) {
                  matchedEquipment[key] = value
                } else {
                  matchedEquipment[key] = value + ' -> ' + addedEquipment[key]
                }
              }
              equipmentMatches.push(matchedEquipment)
            }
          })
        })
        this.showCompareResultsDialog = true
        this.compareResults = equipmentMatches
      },

      checkChanges (value) {
        if (!value) return ''
        return `d-flex align-center pa-2 ${value.includes('->') ? 'amber' : ''}`
      },
      checkFormatter (header, value) {
        if (Object.hasOwn(header, 'formatter')) {
          return header.formatter(value)
        } else {
          return value
        }
      },

      downloadCsv () {
        const csv = convertAuditTablesToCsv(this.possiblyRemovedData, this.possiblyAddedData)
        const encodedData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        const link = document.createElement('a')

        link.setAttribute('href', encodedData)
        link.setAttribute('download', `${this.filename}.csv`)
        link.click()
        this.showCsvDownloadDialog = false
      },

      syncChanges () {
        this.syncingChanges = true
        this.showAlert = false
        ewcApi.convertAllPotentiallyNewAuditEquipmentToEquipmentTable()
          .then(result => {
            console.log(result)
            if (result.data === 'No Change') {
              this.showAlert = true
              this.alertType = 'info'
              this.alertMessage = this.alertMessageNoChange
            } else if (result.data === 'Partial Success') {
              this.showAlert = true
              this.alertType = 'info'
              this.alertMessage = this.alertMessagePartialSuccess
            } else {
              this.showSnackBar = true
              this.snackBarMessage = this.snackBarMessageSyncSuccess
            }
            this.getAllAuditNewEquipment()
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.deleteAuditPotentialAdded()
            } else {
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.syncingChanges = false })
      },

    },
  }
</script>
